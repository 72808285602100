import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { getAuth } from "firebase/auth";
import Loader from "./Loader";
import "./UserDetailsModal.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import EditProfileModal from './EditProfileModal';
import { firestore } from "../firebase";

const UserDetailsModal = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  console.log(user);

  const handleEditProfile = () => {
    setShowUserDetailsModal(false); 
    setShowEditModal(true); 
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setShowUserDetailsModal(true); 
  };

  const handleUpdateProfile = (updatedData) => {
    setUserData(updatedData);
    setProfilePicture(updatedData.profilePicture);
  };
  
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        setLoading(true);
        try {
          const userDoc = await firestore.collection("users").doc(user.uid).get();
          if (userDoc.exists) {
            setUserData(userDoc.data());
            setProfilePicture(user.photoURL || "/images/dp.jpg");
          }else {
            toast.error("User data not found. Please ensure the document exists.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast.error("Failed to fetch user data. Please try again or check permissions.");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      toast.success("Logout successful! See You Again!");
      navigate("/");
    } catch (error) {
      toast.error("Failed to logout. Please try again.");
    }
  };

  return (
    <>
      {showUserDetailsModal && (
        <div className="user-details-modal">
          <div className="user-profile-container">
            {loading ? (
              <div style={{ marginTop: "-200px" }}>
                <Loader className="loader" />
              </div>
            ) : user && userData ? (
              <div>
                <img
                  src={profilePicture || "/images/dp.jpg"}
                  alt="Profile"
                  className="user-profile-image"
                />
                <div className="user-info">
                  <h4 className="user-name">{userData.name || "User Name"}</h4>
                  <p className="user-email">{user.email || "user@example.com"}</p>
                  <hr />
                  <div className="user-actions">
                    <button className="edit-profile bUtton" onClick={handleEditProfile}>
                      <FontAwesomeIcon icon={faUserEdit} style={{ paddingRight: "5px" }} />
                      Update
                    </button>
                    <button className="logout bUtton" onClick={handleLogout}>
                      <FontAwesomeIcon icon={faSignOutAlt} style={{ paddingRight: "5px" }} />
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <p>No user logged in</p>
            )}
          </div>
        </div>
      )}
      <EditProfileModal
        show={showEditModal}
        onClose={handleCloseEditModal}
        onUpdate={handleUpdateProfile}
      />
    </>
  );
};

export default UserDetailsModal;