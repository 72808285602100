import './App.css';
import Navbar from './components/navbar';
import { HashRouter, Route, Routes } from 'react-router-dom';
import BusinessSub from './components/BusinessSub';
import Social from './components/Social';
import MainPage from './components/mainPage';
import HuntsmanThermo from './components/HuntsmanThermo';
import CoastOutdoor from './components/CoastOutdoor';
import DemoDeals from './components/DemoDeals';
import SperosFlashlight from './components/SperosFlashlight';
import SignInSignUpForm from './components/SignInSignUpForm';
import MagneTech from './components/MageTech';
import News from './components/news';
import PrivateRoute from './PrivateRoute';
import { auth } from './firebase';
import { useState, useEffect } from 'react';
import Loader from './components/Loader';
import DealerLocate from './components/DealerLocate';

function App() {
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        setIsAuthLoaded(true);
      } else {
        // User is signed out
        setIsAuthLoaded(true);
      }
    });

    return unsubscribe;
  }, []);

  return isAuthLoaded ? (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<SignInSignUpForm />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Navbar />
                <Routes>
                  <Route path="/home" element={<MainPage />} />
                  <Route path="/business" element={<BusinessSub />} />
                  <Route path="/social" element={<Social />} />
                  <Route path="/HuntsmanThermo" element={<HuntsmanThermo />} />
                  <Route path="/CoastOutdoor" element={<CoastOutdoor />} />
                  <Route path="/DemoDeals" element={<DemoDeals />} />
                  <Route path="/news&update" element={<News />} />
                  <Route path="/SperosFlashlight" element={<SperosFlashlight />} />
                  <Route path="/Magnetech" element={<MagneTech />} />
                  <Route path="/DealerLocate" element={<DealerLocate />} />
                </Routes>
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </div>
  ) : (
    <Loader />
  );
}

export default App;
