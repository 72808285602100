import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import './BusinessSub.css'

const HuntsmanThermo = ()=>{    
    return(
        <div>
          <div style={{position:"relative",height:"250px"}}>
            <img src="/images/bglightcolor.jpg" alt="logo" style={{ width: "100%", height: "100%",objectFit: "cover" }} />
          </div>
        <div className="container" style={{marginBottom:"160px",width:"80%"}}>
        <h3 style={{marginTop:'20px',textAlign:"center"}}>Huntsman Thermography</h3>

        <div className='row'style={{justifyContent:"center"}}>
        <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link target='_blank' rel="noopener noreferrer" to="https://web.facebook.com/HuntsmanThermography?_rdc=1&_rdr"> <img  height="80px" width="80px" alt="" src="./images/roundfb.png"/> </Link>
            </div>
            <h4 className="card-title">Facebook</h4> 
        </div>
        </div>
        </div>

        {/* <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > 
            <img  height="80px" width="80px" src="./images/instabox.png" alt=""/>
             </Link>
            </div>
            <h4 className="card-title">Instagram</h4> 
        </div>
        </div>
        </div> */}

        {/* <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://www.youtube.com/@huntsmanoptics8102" rel="noopener noreferrer" target='_blank' >
                 <img  height="80px" width="80px" src="./images/youtuberedbox.jpeg" alt=""/> 
                 </Link>
            </div>
            <h4 className="card-title">YouTube</h4> 
        </div>
        </div>
        </div> */}

        {/* <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://www.tiktok.com/@huntsman.optics?_t=8m8XFKjbfT0&_r=1" rel="noopener noreferrer" target='_blank' >
                 <img  height="80px" width="80px" src="./images/tiktokbox.jpeg" alt=""/>
                  </Link>
            </div>
            <h4 className="card-title">TikTok</h4> 
        </div>
        </div>
        </div> */}

        <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://www.linkedin.com/company/huntsman-optics-ltd/" rel="noopener noreferrer" target='_blank' >
                 <img  height="80px" width="80px" src="./images/linkdn2.png" alt=""/> 
                 </Link>
            </div>
            <h4 className="card-title">Linkedin</h4> 
        </div>
        </div>
        </div>

        <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
        <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://login.bigcommerce.com/login" rel="noopener noreferrer" target='_blank'>
                 <img  height="80px" width="80px" src="./images/BigCommerce-Quiz.jpg" alt=""/> 
                 </Link>
            </div>
            <h4 className="card-title">BigCommerce</h4> 
        </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
        </div>
    )
}
export default HuntsmanThermo;
