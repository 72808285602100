import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import './EditProfileModal.css';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth, updateProfile } from 'firebase/auth';
import { firestore } from '../firebase';
import ResetPasswordModal from "./ResetPasswordModal";
import { auth } from '../firebase';

const EditProfileModal = ({ show, onClose, onUpdate, userData}) => {
    const [name, setName] = useState(userData?.name || '');
    const [email, setEmail] = useState(userData?.email || '');
    const [profilePicture, setProfilePicture] = useState(userData?.profilePicture || '');
    const [newProfilePicture, setNewProfilePicture] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    
    const handleForgotPassword = () => {
        setShowForgotPassword(true);
      };
    const closeForgotPasswordModal = () => {
        setShowForgotPassword(false);
      };

      const handleForgotPasswordSubmit = async () => {
        try {
          await auth.sendPasswordResetEmail(email);
          toast.success('Password reset email sent successfully!');
          setShowForgotPassword(false);
        } catch (error) {
          console.error('Error sending password reset email:', error);
          toast.error('Failed to send password reset email.');
        }
      };
      const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };

    useEffect(() => {
        const fetchUserData = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                setLoading(true);
                try {
                    const userDoc = await firestore.collection('users').doc(user.uid).get();
                    if (userDoc.exists) {
                        const userData = userDoc.data();
                        setName(userData.name);
                        setEmail(userData.email);
                        setProfilePicture(userData.profilePicture || '/images/dp.jpg');
                    }
                } catch (error) {
                    toast.error('Failed to fetch user data. Please try again.');
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchUserData();
    }, [show]);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setNewProfilePicture(e.target.files[0]);
            setProfilePicture(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleDeleteProfilePicture = async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            setLoading(true);
            try {
                if (user.photoURL && user.photoURL !== '/images/dp.jpg') {
                    const storage = getStorage();
                    const storageRef = ref(storage, `profilePictures/${user.uid}/${user.photoURL.split('/').pop()}`);
                    
                    await deleteObject(storageRef).catch((error) => {
                        if (error.code !== 'storage/object-not-found') {
                            throw error;
                        }
                    });

                    const defaultProfilePic = '/images/dp.jpg';
                    setProfilePicture(defaultProfilePic);

                    await updateProfile(user, {
                        photoURL: defaultProfilePic
                    });

                    await firestore.collection('users').doc(user.uid).update({
                        profilePicture: defaultProfilePic
                    });

                    toast.success('Profile picture deleted successfully!');
                } else {
                    toast.info('No profile picture to delete.');
                }
            } catch (error) {
                toast.error('Failed to delete profile picture. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let profilePicURL = profilePicture;

            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User not authenticated');
            }

            if (newProfilePicture) {
                const storage = getStorage();
                const storageRef = ref(storage, `profilePictures/${user.uid}/${newProfilePicture.name}`);
                await uploadBytes(storageRef, newProfilePicture);
                profilePicURL = await getDownloadURL(storageRef);
            }

            await updateProfile(user, {
                displayName: name,
                photoURL: profilePicURL
            });

            await firestore.collection('users').doc(user.uid).update({
                name: name,
                profilePicture: profilePicURL
            });

            onUpdate({ name, email, profilePicture: profilePicURL });
            toast.success('Profile updated successfully!');
            onClose();
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return show ? (
        <div className="profile-modal-overlay">
            <div className="profile-modal-content">
                <div className="profile-modal-header">
                    <FontAwesomeIcon icon={faTimes} className="profile-close-icon" onClick={onClose} />
                </div>
                <form onSubmit={handleSubmit} className="edit-user-profile-container">
                    <div className="profile-form-group">
                        <img
                            src={profilePicture || '/images/dp.jpg'}
                            alt="Profile"
                            className="user-profile-image"
                        />
                        <div className="edit-delete-icons">
                            <FontAwesomeIcon icon={faEdit} className="icon" onClick={() => document.getElementById('file-input').click()} />
                            <FontAwesomeIcon icon={faTrash} className="icon" onClick={handleDeleteProfilePicture} />
                        </div>
                        <input
                            type="file"
                            id="file-input"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="profile-form-group">
                        <label className="profile-label">Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="profile-input"
                        />
                    </div>
                    <div className="profile-form-group">
                        <label className="profile-label">Email</label>
                        <input
                            type="email"
                            value={email}
                            disabled
                            className="profile-input"
                        />
                    </div>
                    <div className="profile-form-group">
                        <button
                         className='bUtton update-pwd'
                         onClick={handleForgotPassword}
                         type='button'>
                           Change Password
                        </button>
                    </div>
                    <div className="user-actions">
                        <button className="edit-profile profile-update-btn" style={{ width: '120px' }} type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button className="logout bUtton" style={{ width: '120px' }} onClick={onClose} disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
                <ResetPasswordModal
                    isOpen={showForgotPassword}
                    onClose={closeForgotPasswordModal}
                    onSubmit={handleForgotPasswordSubmit}
                    email={email}
                    handleEmailChange={handleEmailChange}
                />
            </div>
        </div>
    ) : null;
};

export default EditProfileModal;
