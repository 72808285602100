import './BusinessSub.css'
import React from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer';

const BusinessSub = function () {
    return <> <div className='container' style={{marginTop:"5%"}}>

<h3 style={{marginTop:'50px'}}>Huntsman Theremography</h3>

      <div className='row'>
      <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link target='_blank' rel="noopener noreferrer" to="https://web.facebook.com/HuntsmanThermography?_rdc=1&_rdr"> <img  height="50px" width="50px" src="./images/roundfb.png" alt=''/> </Link>
            </div>
            <h4 className="card-title">facebook</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/instabox.png" alt=''/> </Link>
            </div>
            <h4 className="card-title">instagrame</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/youtuberedbox.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">youtube</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/tiktokbox.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">TikTok</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/OIP.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">Ebay</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/BigCommerce-Quiz.jpg" alt=''/> </Link>
            </div>
            <h4 className="card-title">Bigcommerse</h4> 
        </div>
      </div>
    </div>
      </div>

      <h3 style={{marginTop:'50px'}}>Coast Outdoor</h3>

        <div className='row'>
        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
          <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
              <div >
              <Link  target='_blank' rel="noopener noreferrer" to="https://web.facebook.com/coastoutdoorswestport/?_rdc=1&_rdr" > <img  height="50px" width="50px" src="./images/roundfb.png" alt='fb'/> </Link>
              </div>
              <h4 className="card-title">facebook</h4> 
          </div>
        </div>
        </div>

<div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
  <div className="card">
    <div className="card-body" style={{textAlign:"center"}}>
      <div >
      <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/instaround.jpeg" alt=''/> </Link>
      </div>
      <h4 className="card-title">instagrame</h4> 
  </div>
</div>
</div>

<div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
  <div className="card">
    <div className="card-body" style={{textAlign:"center"}}>
      <div >
      <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/youtuberedbox.jpeg" alt=''/> </Link>
      </div>
      <h4 className="card-title">youtube</h4> 
  </div>
</div>
</div>

<div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
  <div className="card">
    <div className="card-body" style={{textAlign:"center"}}>
      <div >
      <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/tiktokbox.jpeg" alt=''/> </Link>
      </div>
      <h4 className="card-title">tik tok</h4> 
  </div>
</div>
</div>

<div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
  <div className="card">
    <div className="card-body" style={{textAlign:"center"}}>
      <div >
      <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/OIP.jpeg" alt=''/> </Link>
      </div>
      <h4 className="card-title">ebay</h4> 
  </div>
</div>
</div>

<div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
  <div className="card">
    <div className="card-body" style={{textAlign:"center"}}>
      <div >
      <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/shopifybox.png" alt=''/> </Link>
      </div>
      <h4 className="card-title">shopify</h4> 
  </div>
</div>
</div>
</div>

        <h3 style={{marginTop:'50px'}}>Outdoor demo deals</h3>

        <div className='row'>
        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
          <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
              <div >
              <Link to="https://www.facebook.com/Thermalsimagingoceania/" rel="noopener noreferrer"target='_blank' > <img  height="50px" width="50px" src="./images/roundfb.png" alt=''/> </Link>
              </div>
              <h4 className="card-title">facebook</h4> 
          </div>
        </div>
        </div>

        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
        <div className="card-body" style={{textAlign:"center"}}>
        <div >
        <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/instaround.jpeg" alt=''/> </Link>
        </div>
        <h4 className="card-title">instagrame</h4> 
        </div>
        </div>
        </div>

        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
        <div className="card-body" style={{textAlign:"center"}}>
        <div >
        <Link to=""rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/youtuberedbox.jpeg" alt=''/> </Link>
        </div>
        <h4 className="card-title">youtube</h4> 
        </div>
        </div>
        </div>

        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
        <div className="card-body" style={{textAlign:"center"}}>
        <div >
        <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/tiktokbox.jpeg" alt=''/> </Link>
        </div>
        <h4 className="card-title">tik tok</h4> 
        </div>
        </div>
        </div>

        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
        <div className="card-body" style={{textAlign:"center"}}>
        <div >
        <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/OIP.jpeg" alt=''/> </Link>
        </div>
        <h4 className="card-title">ebay</h4> 
        </div>
        </div>
        </div>

        <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
        <div className="card-body" style={{textAlign:"center"}}>
        <div >
        <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/BigCommerce-Quiz.jpg" alt=''/> </Link>
        </div>
        <h4 className="card-title">BigCommerce</h4> 
        </div>
        </div>
        </div>
        </div>


<h3 style={{marginTop:'50px'}}>Speoas Flashlight & lightning  </h3>

      <div className='row'>
      <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://www.facebook.com/Thermalsimagingoceania/" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/roundfb.png" alt=''/> </Link>
            </div>
            <h4 className="card-title">facebook</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/instabox.png" alt=''/> </Link>
            </div>
            <h4 className="card-title">Instagrame</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank'> <img  height="50px" width="50px" src="./images/youtuberedbox.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">Youtube</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/tiktokbox.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">TikTok</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='blank'> <img  height="50px" width="50px" src="./images/OIP.jpeg" alt=''/> </Link>
            </div>
            <h4 className="card-title">Ebay</h4> 
        </div>
      </div>
    </div>

    <div className="col-xl-2 col-md-6 col-sm-12 mt-3 anime"> 
        <div className="card">
          <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="" rel="noopener noreferrer" target='_blank' > <img  height="50px" width="50px" src="./images/shopifybox.png" alt=''/> </Link>
            </div>
            <h4 className="card-title">Bigcommerse</h4> 
        </div>
      </div>
    </div>
      </div>
      

    </div>
    <Footer/>
    </>
}

export default BusinessSub;