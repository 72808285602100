import React, { useState} from "react";
import { auth, firestore } from '../firebase';
import { toast } from "react-toastify";
import "./SignUpSignIn.css";
import Loader from "./Loader";

const RegisterForm = ({ onSuccessfulRegistration }) => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '', confirmPassword:'' });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword, name } = formData;

    if (!name) {
      toast.error("Please enter a name");
      return;
    }
    if (!email) {
      toast.error("Please enter an email");
      return;
    }
    if (!password) {
      toast.error("Please enter a strong password");
      return;
    }
    if (!confirmPassword) {
      toast.error("Please confirm your password");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const emailRegex = /^[^@\s]+@huntsmanoptics\.com$/;

    if (!emailRegex.test(email)) {
      toast.error("Your email syntex should be '<Name>@huntsmanoptics.com'");
      setFormData({ email: '' });
      return;
    }

    try {
      setIsLoading(true);

      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      await user.sendEmailVerification();

      const userId = user.uid;
      await firestore.collection("users").doc(userId).set({
        name,
        email,
        emailVerified: false,
      });

      toast.success("Please check your email and verify your account.");
      onSuccessfulRegistration();

      setFormData({ name: '', email: '', password: '', confirmPassword: '' });

      // Start checking for email verification
      startEmailVerificationCheck(userId);
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Email already exists. Please use a different email.');
      } else {
        toast.error("Error while creating the user");
      }
      setFormData({ email: '' });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to check if the user's email is verified and update the Firestore document
  const checkEmailVerification = async (userId) => {
    const user = auth.currentUser;
    if (user) {
      await user.reload();
      if (user.emailVerified) {
        await firestore.collection("users").doc(userId).update({
          emailVerified: true,
        });
        toast.success("Email verified successfully.");
        return true;
      }
    }
    return false;
  };

  const startEmailVerificationCheck = (userId) => {
    const checkInterval = 5000; // Check every 5 seconds
    const intervalId = setInterval(async () => {
      const isVerified = await checkEmailVerification(userId);
      if (isVerified) {
        clearInterval(intervalId);
      }
    }, checkInterval);

    auth.onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        clearInterval(intervalId);
        await firestore.collection("users").doc(user.uid).update({
          emailVerified: true,
        });
        toast.success("Email verified successfully.");
      }
    });
  };

  const heading = { fontWeight: "bold", marginBottom: "15px" };

  return (
    <div className="form-containerA sign-up-containerA">
      {isLoading && 
        <div className="loader1-container">
          <Loader />
        </div>
      }
      <form onSubmit={handleSignUp} className="form1">
        <h2 style={heading}>Create Account</h2>
        <input
          type="text"
          placeholder="Name"
          className="input-common"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          placeholder="Email"
          className="input-common"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Password"
          className="input-common"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className="input-common"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
        />
        <button type="submit" className="cmn-btn">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
