import "./HomePageGrid.css";
import React from "react";

const DealerLocate = function(){
    return(
        <div>
            <div className="container main-cent" style={{marginTop:"60px"}}>
                <div className="cent">
                    <div className="market">
                        <h2 className="under">Dealer Locator</h2>
                        <hr style={{ margin: "0",borderTop:"5px solid #ddd" }}></hr>
                        <div className="row no-gutters mt-5 home_row" style={{justifyContent:"center"}}>
                            {/*1st  Single Row with 7 Columns */}
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://www.google.com/maps/d/u/0/edit?mid=1ql0I1MmqK6EObXZguJRCugv3vsGU7qM&ll=-5.600367069446342%2C0&z=3" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/socialM.png" alt="Internal Drive" />
                                        </a>
                                        <h4 className="card-title">Website Map</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href="https://www.google.com/maps/d/edit?mid=1OllGBdHpIpSFEO3IRSrfEGTeridK3D0&ll=-34.84650814552343%2C144.24974211227115&z=7" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/onsite.png" alt="HO Assets" />
                                        </a>
                                        <h4 className="card-title">Travis Map-HO TNVS</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 col-sm-6 mt-3 anime-2 center-card">
                                <div className="card home_card">
                                    <div className="card-body" style={{ textAlign: "center" }}>
                                        <a href=" https://www.google.com/maps/d/viewer?mid=1YV6ht6KLWv9nTO599HzFCalF630ZZzCZ&ll=-9.401556572885168%2C162.46399975000003&z=3" target="_blank" rel="noreferrer">
                                            <img height="100px" width="100px" src="./images/off.png" alt="Hikmicro Thermal" />
                                        </a>
                                        <h4 className="card-title">Database Map</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    )

}
export default DealerLocate;