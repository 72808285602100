import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBSl07H67aSQfbn5WDR0IWVKZgvdtU62us",
  authDomain: "hointernal-f62cf.firebaseapp.com",
  projectId: "hointernal-f62cf",
  storageBucket: "hointernal-f62cf.appspot.com",
  messagingSenderId: "438378494124",
  appId: "1:438378494124:web:839a0703c29391aa51c709"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export default firebase;