import './BusinessSub.css'
import React from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer';

const DemoDeals = ()=>{ 
    return(
        <div>
            <div style={{position:"relative",height:"250px"}}>
                <img src="/images/bglightcolor.jpg" alt="logo" style={{ width: "100%", height: "100%",objectFit: "cover" }} />
            </div>
            <div className='container'style={{marginBottom:"170px",width:"80%"}}>
            <h3 style={{marginTop:'20px',textAlign:"center"}}>Outdoor demo deals</h3>
                <div className='row' style={{justifyContent:"center"}}>
                <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
                <div className="card">
                    <div className="card-body" style={{textAlign:"center"}}>
                    <div >
                    <Link to="https://www.facebook.com/profile.php?id=61558522740278" rel="noopener noreferrer"target='_blank' > <img  height="80px" width="80px" src="./images/roundfb.png" alt='fb'/> </Link>
                    </div>
                    <h4 className="card-title">facebook</h4> 
                </div>
                </div>
                </div>

                <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
                <div className="card">
                <div className="card-body" style={{textAlign:"center"}}>
                <div >
                <Link to="https://www.instagram.com/outdoordemodeals/" rel="noopener noreferrer" target='_blank'> 
                    <img  height="80px" width="80px" src="./images/instaround.jpeg" alt='insta'/>
                 </Link>
                </div>
                <h4 className="card-title">instagram</h4> 
                </div>
                </div>
                </div>

                <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
                <div className="card">
                <div className="card-body" style={{textAlign:"center"}}>
                <div >
                <Link to="https://outdoordemodeals.com/"rel="noopener noreferrer" target='_blank' >
                     <img  height="80px" width="80px" src="./images/websiteicon.jpeg" alt='yt'/>
                      </Link>
                </div>
                <h4 className="card-title">Web site</h4> 
                </div>
                </div>
                </div>




            </div>
            </div>
            <Footer/>
        </div>
    )
}
export default DemoDeals;