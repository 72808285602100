import React, { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ResetPasswordModal from "./ResetPasswordModal";
import "./SignUpSignIn.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from '../firebase';


const SignInSignUpForm = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');

  const toggleSignUpForm = () => {
    setIsSignUp((prevState) => !prevState);
  };

  const handleForgotPassword = () => {
    setEmail("");
    setShowForgotPassword(true);
  };

  const closeForgotPasswordModal = () => {
    setShowForgotPassword(false);
  };

  const handleForgotPasswordSubmit = async () => {
    const emailRegex = /^[^@\s]+@huntsmanoptics\.com$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email syntax");
      setEmail('');
      return;
    }
    try {
      await auth.sendPasswordResetEmail(email);
      toast.success('Password reset email sent successfully!');
      setShowForgotPassword(false);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      toast.error('Failed to send password reset email.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div style={bodyStyle}>
      <div className={`containerA ${isSignUp ? "right-panel-active" : ""}`} id="containerA">
        {isSignUp ? (
          <RegisterForm onSuccessfulRegistration={toggleSignUpForm} />
        ) : (
          <LoginForm onForgotPassword={handleForgotPassword} />
        )}
        <div className="overlay-containerA">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1 className="heading1">Welcome Back!</h1>
              <p>To keep connected with us please login with your personal info</p>
              <button className="ghost cmn-btn" onClick={() => setIsSignUp(false)}>
                Log In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1 className="heading1">Hello, Staff!</h1>
              <p>Enter your personal details and start journey with us</p>
              <button className="ghost cmn-btn" onClick={() => setIsSignUp(true)}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <ResetPasswordModal
        isOpen={showForgotPassword}
        onClose={closeForgotPasswordModal}
        onSubmit={handleForgotPasswordSubmit}
        email={email}
        handleEmailChange={handleEmailChange}
      />
    </div>
  );
};

const bodyStyle = {
  background: "#f6f5f7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: `'Montserrat', sans-serif`,
  height: "100vh",
};

export default SignInSignUpForm;