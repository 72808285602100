import React from "react";
import Footer from './Footer';
import './BusinessSub.css'
import { Link } from "react-router-dom";

const CoastOutdoors =()=>{
    return(
        <div>
            <div style={{position:"relative",height:"250px"}}>
                <img src="/images/bglightcolor.jpg" alt="logo" style={{ width: "100%", height: "100%",objectFit: "cover" }} />
            </div>
        <div className='container' style={{marginBottom:"170px",width:"80%"}}>
            <h3 style={{marginTop:'20px',textAlign:"center"}}>Coast Outdoors</h3>
            <div className='row' style={{justifyContent:"center"}}>
            <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
            <div className="card">
                <div className="card-body" style={{textAlign:"center"}}>
                <div >
                <Link  target='_blank' rel="noopener noreferrer" to="https://www.facebook.com/coastoutdoorswestport" > <img  height="80px" width="80px" src="./images/roundfb.png" alt='fb'/> </Link>
                </div>
                <h4 className="card-title">facebook</h4> 
            </div>
            </div>
            </div>


          

            <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
            <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://admin.shopify.com/store/habitatsports" rel="noopener noreferrer" target='_blank' >
                 <img  height="80px" width="80px" src="./images/shopifybox.png" alt=""/>
                  </Link>
            </div>
            <h4 className="card-title">shopify</h4> 
            </div>
            </div>
            </div>

            <div className="col-xl-4 col-md-4 col-sm-12 mt-3 anime"> 
            <div className="card">
            <div className="card-body" style={{textAlign:"center"}}>
            <div >
            <Link to="https://coastoutdoors.co.nz/" rel="noopener noreferrer" target='_blank' >
                 <img  height="80px" width="80px" src="./images/websiteicon.jpeg" alt=""/> 
                 </Link>
            </div>
            <h4 className="card-title">Web Site</h4> 
            </div>
            </div>
            </div>
            </div>
        </div>
        <Footer/>
        </div>
    )
}
export default CoastOutdoors;